import React, { useState, useEffect, createContext, useReducer } from "react";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getSigleProductFail,
  getSigleProductStart,
  getSigleProductSuccess,
} from "../Redux/slices/singleProduct";
import { useDispatch, useSelector } from "react-redux";
import { cartStart, cartFailure, cartSuccess } from "../Redux/slices/cart";
import {
  wiseListStart,
  wiseListSuccess,
  wiseListFailure,
} from "../Redux/slices/wishList";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  UpOutlined,
  DownOutlined,
  HeartOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Divider,
  Row,
  Col,
  Tabs,
  Tooltip,
  Alert,
  Modal,
  Button,
  Image,
  List,
  Rate,
  Form,
  Input,
  message,
  Card,
  Radio,
  Skeleton,
  Tag,
  Space,
  Spin,
} from "antd";
import { Comment } from "@ant-design/compatible";
import Default from "../Assets/Images/default.png";
import API from "../ApiService/ApiService";
import moment from "moment";
import crud_service from "../ApiService/CrudService";
import { theme, font } from "../ApiService/Theme";
import App from "../App";
import { MediaPlayer, MediaProvider } from "@vidstack/react";
import VideoPlay from "../Assets/Images/video.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs, Mousewheel } from "swiper";
import "swiper/swiper-bundle.css";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default";
import styles from "../ApiService/Data";
import {
  userGetAllStart,
  userGetAllSuccess,
  userGetAllFail,
} from "../Redux/slices/user";
import { company_code, base_url, api_website, api_admin } from "../config";
import dayjs from "dayjs";

const crud = new crud_service();

const { TextArea } = Input;

const ProductContext = createContext();
SwiperCore.use([Navigation, Thumbs]);
let specificationSet;
let user = "";

const Products = () => {
  const CurrentDate = new dayjs();
  // console.log("CurrentDate", CurrentDate);
  const location = useLocation();
  // const loginTrue = localStorage.getItem("login");
  const loginTrue = localStorage.getItem("login");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const api = new API();
  const [products, setProducts] = useState([]);
  const [specData, setSpecData] = useState([]);
  const [isVideoVisible, setVideoVisible] = useState(false);
  const [count, setCount] = useState(1);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [wishListLoading, setWishListLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [totalProducts, setTotalProducts] = useState([]);
  const [filterSpecs, setFilterSpecs] = useState([]);
  const [foundSpectId, setFoundSpectId] = useState(null);
  const [foundSpectId1, setFoundSpectId1] = useState(null);
  const [foundSpectQty, setFoundSpectQty] = useState(0);
  const [foundSpectImg, setFoundSpectImg] = useState(null);
  const [relatedProClicked, setRelatedProClicked] = useState(false);
  const [foundSpectImg1, setFoundSpectImg1] = useState([]);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [rating, setRating] = useState([]);
  const getUser = localStorage.getItem("user");
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const dispatch = useDispatch();
  const params = useParams();
  const [isSaving, setSaving] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [reviews, setreviews] = useState([]);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [selectedRelatedProducts, setSelectedRelatedProducts] = useState([]);
  const [discountsrules, setProductsDiscountDetails] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  // const [filterSku, setFilterSku] = useState(specData[0]?.sku);
  // console.log("filterSku", filterSku);
  // console.log("specData", specData?.[0]?.discount_id?.discount_id?.discount_valid_to);
  // console.log("relatedProducts", relatedProducts);
  // console.log("totalProducts", totalProducts);
  // console.log("params", params);

  // console.log("showAlert", showAlert);

  const RelatedProductsFilter = relatedProducts?.filter(
    (item) => item?.sku !== specData?.[0]?.sku
  );
  // console.log("RelatedProductsFilter", RelatedProductsFilter);
  const [loginForm] = Form.useForm();

  const navigate = useNavigate();

  // console.log("loginTrue--->", loginTrue);

  if (getUser) {
    user = JSON.parse(localStorage.getItem("user"));
  } else {
    user = "";
  }

  // console.log("params above??", params);

  // console.log("Total Products-->", totalProducts);
  useEffect(() => {
    getSingleProduct();
    getRating();
    getProductDiscountDetails();
  }, []);

  // console.log("totalProducts 148", totalProducts);

  const getSingleProduct = () => {
    // console.log("value", value);
    api.getSingleProduct(params?.id).then((res) => {
      // console.log("productspec", res?.data?.data?.productspec);
      setProducts(res?.data?.data?.product);
      setTotalProducts(res?.data?.data);
      setSpecData(res?.data?.data?.productspec);
      setreviews(res?.data?.data?.reviews);
      setRelatedProducts(res?.data?.data?.relatedProducts);

      const initialParam = {
        spec_ids: [],
        detail_ids: [],
      };

      res?.data?.data?.product?.specification?.forEach((spec, index) => {
        const defaultDetailId = spec?.specification_details[0]?._id;
        initialParam.spec_ids.push(
          spec?.specification_details[index]?.specification_id
        );
        // initialParam.spec_ids.push(spec?.specification?._id);
        initialParam.detail_ids.push(defaultDetailId);
      });

      // setParam(initialParam);
      setInitialLoading(false);
    });
  };

  // useEffect(() => {
  //   var a = 0;

  //   for(a; a < totalProducts?.product?.specification?.specification_details?.length; a++) {
  //     console.log("a", a);
  //   }

  // }, [totalProducts]);

  const getSingleProduct1 = (value, index) => {
    // console.log("index", index);
    api.getSingleProduct(value ? value : params?.id).then((res) => {
      // console.log("setSpecData1", res?.data?.data?.relatedProducts[index]?._id);
      const setSpecData11 = res?.data?.data?.relatedProducts[index]?._id;
      addToCart1(setSpecData11);
    });
  };

  const getProductDiscountDetails = () => {
    api.getProductDiscountDetails(params?.id).then((res) => {
      //  console.log( res?.status)
      if (res?.status == 200) {
        //  console.log("product id ===>"+params?.id);
        //  console.log("ProductDiscountDetails fetched successfully");
        setProductsDiscountDetails(res?.data?.data?.discounts);
        //console.log("discountsrules 222222222===>"+res?.data?.data?.discounts);
        //  console.log("discountdata data===>"+discountsrules.length)
        setInitialLoading(false);
      } else {
        // console.log("ProductDiscountDetails not available");
      }
    });
  };

  const getRating = () => {
    api.getCommonApiRating().then((res) => {
      setRating(res?.data?.data);
      setInitialLoading(false);
    });
  };

  const addReview = (values) => {
    const combinedData = {
      title: {
        ...values,
        product_id: params?.id,
      },
      mode: "rating",
    };

    api.createCommonRatingApi(combinedData).then((res) => {
      if (res?.status == 201) {
        message.success("Rating added successfully");
        form.resetFields();
        getRating();
      } else {
        message.error("Something went wrong");
      }
    });
  };

  const item = [
    {
      href: "/",
      title: <HomeOutlined />,
    },
    // {
    //   href: "",
    //   title: (
    //     <p onClick={()=>navigate(`/Products?catName=${totalProducts?.product?.category[0]?.name
    //       ?.replace(" ", "-")
    //       ?.toLowerCase()
    //       ?.replace(/ /g, "")
    //       ?.replace(/[^\w-]+g/, "")}`)}>
    //       {totalProducts?.product?.category?.map((e) => {
    //         return <p>{e?.name}</p>;
    //       })}
    //     </p>
    //   ),
    // },
    {
      href: "/products",
      title: <>Products</>,
    },
    {
      title: <>{specData?.[0]?.product_id?.product_name}</>,
    },
  ];

  const allReviews = [];

  // const pro = [];
  // for (let i = 0; i < 4; i++) {
  //   pro.push(
  //     <div className="product_align_filter_box">
  //       <div className="p_image">
  //         <img src={Default} alt="Default" />
  //       </div>
  //       <div className="p_content">
  //         <h2>Keychain With Pendrive</h2>
  //         <div className="price">
  //           <div className="sp">₹ 950</div>
  //           <div className="mrp">₹ 1000</div>
  //         </div>
  //         <div>
  //           <Button size="medium">Buy Now</Button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // {
  //   relatedProducts?.map((item) => {
  //     <div className="product_align_filter_box">
  //       <div className="p_image">
  //         <img src={api.rootUrl1 + item?.product_images[0]?.image_path} alt="Default" />
  //       </div>
  //       <div className="p_content">
  //         <h2>{item?.product_id?.product_name}</h2>
  //         <div className="price">
  //           <div className="sp">₹ {item?.sp}</div>
  //           <div className="mrp">₹ {item?.mrp}</div>
  //         </div>
  //         <div>
  //           <Button size="medium">Buy Now</Button>
  //         </div>
  //       </div>
  //     </div>
  //   })
  // }

  const initialMainImage =
    totalProducts?.productspec?.product_images?.[0]?.image_path || null;
  const [mainImage, setMainImage] = useState(initialMainImage);

  const productspecification = products?.prospecdocs?.map(
    (item) => item?.specification
  );

  const handleThumbnailClick = (imagePath) => {
    setMainImage(imagePath);
  };

  const addToCart = () => {
    setAddToCartLoading(true);

    // console.log("addToCartID user", user);

    // console.log("addToCartID company_code", company_code);

    if (!loginTrue) {
      setAddToCartLoading(false);

      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: count,
        product_id: specData[0]?.product_id?._id,
        product_spec: foundSpectId ? foundSpectId : specData[0]?._id,
        company_code: company_code,
        // company_code: user?.company_code,
      };
      // console.log("combinedDataCart", combinedData);
      api.addCart(combinedData).then((res) => {
        // console.log("res--->", res);
        if (res?.status == 200) {
          dispatch(cartStart());
          // dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          setAddToCartLoading(false);
          // window.location.reload();
        } else {
          // console.log("res--->", res?.response?.data?.message);
          setOpen1(true);
          dispatch(cartFailure());
          // dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          setAddToCartLoading(false);
        }
      });
    }
  };
  const addToCart1 = (setSpecData11) => {
    setAddToCartLoading(true);

    if (!loginTrue) {
      setAddToCartLoading(false);

      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: count,
        product_id: specData[0]?.product_id?._id,
        product_spec: setSpecData11 ? setSpecData11 : specData?._id,
      };
      // console.log("combinedDataCart", combinedData);
      api.addCart(combinedData).then((res) => {
        if (res?.status == 200) {
          dispatch(cartStart);
          api.getcart().then((res) => {
            // console.log("res--->", res);
            dispatch(cartSuccess(res?.data));
          });
          message.success(res?.data?.message);
          setAddToCartLoading(false);
          // window.location.reload();
        } else {
          // console.log("res--->", res?.response?.data?.message);
          dispatch(cartFailure);
          message.error(res?.response?.data?.message);
          setAddToCartLoading(false);
        }
      });
    }
  };
  const addToWiseList = () => {
    setWishListLoading(true);

    if (!loginTrue) {
      setWishListLoading(false);
      setOpen(true);
    } else {
      const combinedData = {
        buyer_id: user?._id,
        qty: count,
        product_id: specData[0]?.product_id?._id,
        product_spec: foundSpectId1 ? foundSpectId1 : specData[0]?._id,
        company_code: user?.company_code,
      };
      // console.log("combinedDataWishlist", combinedData);
      api.addWiseList(combinedData).then((res) => {
        // console.log(res);
        if (res?.status == 201) {
          dispatch(wiseListStart());
          // dispatch(wiseListStart);
          api.getWishList().then((res) => {
            // console.log("res--->", res);
            dispatch(wiseListSuccess(res?.data));
          });
          message.success(res?.data?.message);
          setWishListLoading(false);
          // window.location.reload();
        } else {
          dispatch(wiseListFailure());
          // dispatch(wiseListFailure(res?.data));
          message.error(res?.response?.data?.message);
          setWishListLoading(false);
        }
      });
    }
  };

  const handleClickVideo = () => {
    setVideoVisible(true);
  };

  const [param, setParam] = useState({
    spec_ids: [],
    detail_ids: [],
  });

  const handleSpecClick = async (index, detail_id, spec_id, prod_id) => {
    setFoundSpectId(null);
    setFoundSpectId1(null);
    setFoundSpectQty(null);
    setFoundSpectImg(null); 
    setSkeletonLoading(true);
    // console.log("param", param);
    const updatedParam = { ...param };
    // console.log("updatedParam ??", updatedParam);
    // console.log("spec_id", spec_id);

    const specIndex = updatedParam?.spec_ids?.indexOf(spec_id);
    // console.log("specIndex", specIndex);
    const detailIndex = updatedParam?.detail_ids?.indexOf(detail_id);

    if (specIndex !== -1) {
      // updatedParam.spec_ids.splice(specIndex, 1);
      // updatedParam.detail_ids.splice(specIndex, 1);

      // Replace the corresponding detail_id if spec_id is already in the array
      updatedParam.detail_ids[specIndex] = detail_id;
    } else {
      // Otherwise, push the new spec_id and detail_id
      updatedParam.spec_ids.push(spec_id);
      updatedParam.detail_ids.push(detail_id);
    }

    // updatedParam.spec_ids.push(spec_id);
    // updatedParam.detail_ids.push(detail_id);
    // console.log("updatedParam---->", updatedParam);

    try {
      const res = await api.getproductspec(updatedParam, prod_id); // Pass updatedParam instead of param
      const data = res?.data?.data?.productspec;
      setSpecData(data);
      setSkeletonLoading(false);

      const filteredSpecs = data?.map((spec) => {
        return spec?.specification?.map((insidespec) => {
          return insidespec?.specification_details;
        });
      });
      // console.log("i am here indside");
      setFilterSpecs([...filteredSpecs.flat()]);
      setParam(updatedParam);
      setMainImage("");
      localStorage.setItem("spec_data", JSON.stringify(data));
    } catch (err) {
      message.error("Something Went Wrong");
      setSkeletonLoading(false);
    }
  };

  // const handleSpecClick = async (index, detail_id, spec_id, prod_id) => {
  //   setSkeletonLoading(true);
  //   const updatedParam = { ...param };
  //   // console.log("updatedParam---->", updatedParam);

  //   const specIndex = updatedParam?.spec_ids?.indexOf(spec_id);
  //   const detailIndex = updatedParam?.detail_ids?.indexOf(detail_id);

  //   if (specIndex !== -1) {
  //     updatedParam?.spec_ids?.splice(specIndex, 1);
  //     updatedParam?.detail_ids?.splice(specIndex, 1);
  //   }

  //   if (detailIndex !== -1) {
  //     updatedParam?.spec_ids?.splice(detailIndex, 1);
  //     updatedParam?.detail_ids?.splice(detailIndex, 1);
  //   }

  //   updatedParam?.spec_ids?.push(spec_id);
  //   updatedParam?.detail_ids?.push(detail_id);

  //   try {
  //     const res = await api.getproductspec(param, prod_id);
  //     const data = res?.data?.data?.productspec;
  //     setSpecData(data);

  //     const filteredSpecs = data?.map((spec) => {
  //       // console.log("spec", spec);
  //       return spec?.specification?.map((insidespec) => {
  //         return insidespec?.specification_details;
  //       });
  //     });
  //     setFilterSpecs([...filteredSpecs.flat()]);
  //     setParam(updatedParam);
  //     setMainImage("");
  //     setSkeletonLoading(false);
  //     localStorage.setItem("spec_data", JSON.stringify(data));
  //   } catch (err) {
  //     message.error("Something Went Wrong");
  //     setSkeletonLoading(false);
  //   }
  // };

  // console.log("filter-->", filterSpecs);

  const handleChangeIncrease = (type, data) => {
    // console.log("type", type);
    // console.log("data", data)
    if (type === "minus") {
      setCount(count > 1 ? count - 1 : 1);
    } else if (type === "plus") {
      setCount(count < data ? count + 1 : count);
    }
  };

  const primaryArray = [];
  const secondaryArray = [];

  totalProducts?.product?.specification?.slice(0, 1)?.map((item) => {
    item?.specification_details?.map((e) => {
      return primaryArray.push(e?.specification_details);
    });
  });
  totalProducts?.product?.specification?.slice(1)?.map((item) => {
    item?.specification_details?.map((e) => {
      return secondaryArray.push(e?.specification_details);
    });
  });

  const htmlContent = totalProducts?.product?.product_description;
  // console.log("htmlContent", htmlContent);
  const htmlContentdasd = totalProducts?.product?.product_description
    .split(" ")
    .slice(0, 20)
    .join(" ");

  const items = [
    {
      key: "1",
      label: (
        <div className="label" id="descriptionsec">
          <h5>Descriptions</h5>
        </div>
      ),
      children: (
        <div
          dangerouslySetInnerHTML={{ __html: htmlContent }}
          style={{ overflowWrap: "anywhere" }}
        ></div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="label">
          <h5>Specifications</h5>
        </div>
      ),
      children: (
        <div className="specifications">
          {totalProducts?.product?.specification?.map((spec, i) => (
            <>
              <p key={i}>
                <b>{spec?.specification?.name} :</b>{" "}
                {spec?.specification_details?.map((item) => (
                  <>{item?.specification_details}, </>
                ))}
              </p>
            </>
          ))}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div className="label">
          <h5>Additional Information</h5>
        </div>
      ),
      children: (
        <div>
          {/* <h5>Company Details</h5> */}
          <p>
            <b>Company Name:</b>{" "}
            {totalProducts?.productsellerinfo?.[0]?.company_name},
          </p>
          <p>
            <b>Website:</b> {totalProducts?.productsellerinfo?.[0]?.website},
          </p>
          <p>
            <b>Email:</b> {totalProducts?.productsellerinfo?.[0]?.email_id},
          </p>
          <p>
            <b>GST No:</b> {totalProducts?.productsellerinfo?.[0]?.gstin},
          </p>
          <p>
            <b>Contact Number:</b>{" "}
            {totalProducts?.productsellerinfo?.[0]?.mobile_number},
          </p>
          <p>
            <b>Company Addresss:</b>{" "}
            {totalProducts?.productsellerinfo?.[0]?.company_addresss} -{" "}
            {totalProducts?.productsellerinfo?.[0]?.pin_code},
          </p>
          <p>
            <b>State:</b> {totalProducts?.productsellerinfo?.[0]?.state}.
          </p>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <div className="label">
          <h5>Review</h5>
        </div>
      ),
      children: (
        <div className="review_section">
          <div className="review_left">
            {/* <h2>Review</h2> */}
            <Card
              bordered={true}
              className="Review_Section"
              title={reviews?.length + " " + "Reviews"}
              style={{ height: "400px", overflowX: "auto" }}
            >
              <List
                className="comment-list"
                itemLayout="horizontal"
                dataSource={reviews}
                renderItem={(item) => (
                  <li key={item?._id}>
                    <Comment
                      // actions={item?.actions}
                      author={item?.buyer_id?.buyer_name}
                      // avatar={Default}
                      content={item?.review}
                      datetime={moment
                        .utc(item?.createdAt)
                        .local()
                        .startOf("seconds")
                        .fromNow()}
                    />
                    <div className="rating_design">
                      <Rate disabled defaultValue={item?.rating} />
                    </div>
                  </li>
                )}
              />
            </Card>
            <br />
          </div>
        </div>
      ),
    },
  ];

  const onChangeTabs = (key) => {
    // console.log(key);
  };

  const login = async (values) => {
    setLoginLoading(true);
    let user;
    try {
      user = await api.create(
        dispatch,
        [userGetAllStart, userGetAllSuccess, userGetAllFail],
        "login",
        values
      );
    } catch (error) {
      message.error("Something went wrong");
      console.error("Login error:", error);
    }
    if (user?.status === 200) {
      // console.log("res--------->", user);
      message.success("Login Successful");
      localStorage.setItem("token", user?.data?.token);
      localStorage.setItem("user", JSON.stringify(user?.data?.data));
      localStorage.setItem("login", true);
      localStorage.setItem("buyer_name", values?.buyer_number);
      localStorage.setItem("buyer_pasword", values?.buyer_password);
      setLoginLoading(false);
      setOpen(false);
      window.location.reload();
    } else {
      message.error(user?.response?.data?.message);
      setLoginLoading(false);
    }
  };

  const handleOk = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleCancel = () => {
    loginForm.resetFields();
    setOpen(false);
  };
  const handleOk1 = () => {
    setOpen1(false);
  };
  const handleCancel1 = () => {
    setOpen1(false);
  };

  // console.log("specData 702", specData);
  // console.log("styles 703", styles);
  // console.log(specData?.[0]?.discount_id, 'specData?.[0]?.discount_id')

  const relatedProductClick = async (item, index) => {
    setSkeletonLoading(true);
    setFoundSpectId(null);
    setFoundSpectId1(null);
    setFoundSpectQty(null);
    setFoundSpectImg(null); 
    setRelatedProClicked(true);

    // console.log("item", item);
    // console.log("index", index);
    const product_Id = item?.product_id?._id;

    const spec_ids = [];
    const detail_ids = [];

    item?.specification.map((e, i) => {
      spec_ids.push(e?.specification_id);
      detail_ids.push(e?._id);
    });
    // console.log("spec_ids", spec_ids);
    // console.log("detail_ids", detail_ids);

    const updatedParam = {
      detail_ids: detail_ids,
      spec_ids: spec_ids,
    };
    // console.log("updatedParam", updatedParam);
    // console.log("product_Id", product_Id);
    try {
      const res = await api.getproductspec(updatedParam, product_Id);
      const data = res?.data?.data?.productspec;
      setSpecData(data);
      setTotalProducts(res?.data?.data);
      setreviews(res?.data?.data?.reviews);
      setProducts(res?.data?.data?.product);

      const filteredSpecs = data?.map((spec) => {
        return spec?.specification?.map((insidespec) => {
          return insidespec?.specification_details;
        });
      });
      setFilterSpecs([...filteredSpecs.flat()]);
      setParam(updatedParam);
      setMainImage("");
      setSkeletonLoading(false);
      localStorage.setItem("spec_data", JSON.stringify(data));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (err) {
      message.error("Something Went Wrong");
      setSkeletonLoading(false);
    }
  };

  // console.log(totalProducts?.product?.tax_percentage
  //   ?.tax_percentage / 100, '------');

  useEffect(() => {
    if (totalProducts?.product?.specification) {

      const newSpecIds = [];
      const newDetailIds = [];

      totalProducts.product.specification.forEach((spec) => {
        const foundSpec = totalProducts?.productspec?.find(
          (prodSpec) =>
            prodSpec?.quantity > 0 &&
            prodSpec?.specification.some(
              (specDetail) =>
                specDetail?.specification_id === spec?.specification?._id
            )
        );

        // console.log("foundSpec??", foundSpec);
        
        if (foundSpec) {
          setFoundSpectId(foundSpec?._id);
          setFoundSpectId1(foundSpec?._id);
          setFoundSpectQty(foundSpec?.quantity);
          setFoundSpectImg(foundSpec?.product_images);
          // setFoundSpectImg1(foundSpec?.product_images?.[0]?.image_path);

          // Update initial param here instead of defaulting to 0th index
        const defaultSpecDetailId = foundSpec?.specification.find(
          (specDetail) => specDetail?.specification_id === spec?.specification?._id
        )?._id;

        // Update spec_ids and detail_ids arrays
        if (spec?.specification?._id && defaultSpecDetailId) {
          newSpecIds.push(spec?.specification?._id);
          newDetailIds.push(defaultSpecDetailId);
        }
        
        // // Update the param to reflect the found default specification detail ID
        // setParam((prev) => ({
        //   ...prev,
        //   spec_ids: [...prev.spec_ids, spec?.specification?._id],
        //   detail_ids: [...prev.detail_ids, defaultSpecDetailId],
        // }));
        }
      });

      // Update param with new specification and detail IDs
    setParam((prev) => ({
      ...prev,
      spec_ids: newSpecIds,
      detail_ids: newDetailIds,
    }));

    }
  }, [totalProducts]);

  // console.log("foundSpectQty", foundSpectQty);

  return (
    <>
      {initialLoading == true ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            zIndex: 999,
          }}
        ></Spin>
      ) : (
        <ProductSection>
          {products && (
            <div className="wrapper" style={{ padding: "60px 40px" }}>
              <div className="breadcrumb_section">
                <Breadcrumb items={item} />
              </div>

              <div className="individual_product_section">
                <div className="individual_product_align">
                  <div className="individual_product_left">
                    {/* {console.log("specData", specData?.length)} */}
                    {/* {specData?.length <= 0 && (
                    <Alert
                      style={{ marginTop: "200px" }}
                      message="Stock Not Available."
                      type="warning"
                      showIcon
                    />
                  )} */}
                    {/* {specData?.length <= 0 && (
                      <Spin
                        tip="Loading"
                        size="large"
                        style={{
                          position: "fixed",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                          zIndex: 999,
                        }}
                      ></Spin>
                    )} */}
                    {specData?.slice(0, 1)?.map((item, index) => (
                      // console.log("item----------->", item),
                      <div>
                        {skeletonLoading == true ? (
                          <Skeleton active />
                        ) : (
                          <>
                            <div className="individual_product_left_wrap">
                              <div className="thump_image">
                                <div
                                  className="slider__prev"
                                  // onClick={handlePrevClick}
                                >
                                  <UpOutlined />
                                </div>
                                <Swiper
                                  onSwiper={() => setThumbsSwiper(thumbsSwiper)}
                                  mousewheel={true}
                                  slidesPerView={
                                    window.innerWidth <= 540 ? 2 : 3
                                  }
                                  direction="vertical"
                                  spaceBetween={10}
                                  className="swiper"
                                  // loop={true}

                                  navigation={{
                                    nextEl: ".slider__next",
                                    prevEl: ".slider__prev",
                                  }}
                                  thumbs={{ swiper: thumbsSwiper }}
                                  modules={[Navigation, Thumbs, Mousewheel]}
                                >
                                 {foundSpectImg ? (foundSpectImg &&
                                    foundSpectImg?.map((e, i) => (
                                      <SwiperSlide
                                        key={i}
                                        draggable={true}
                                        className="thump_image_box"
                                        onClick={() =>
                                          handleThumbnailClick(e?.image_path)
                                        }
                                      >
                                        <img
                                          preview={false}
                                          height={100}
                                          src={api.rootUrl1 + e?.image_path}
                                          alt={`Thumbnail ${i}`}
                                        />
                                      </SwiperSlide>
                                    ))) :
                                  (item?.product_images &&
                                    item?.product_images?.map((e) => (
                                      <SwiperSlide
                                        key={e?._id}
                                        draggable={true}
                                        className="thump_image_box"
                                        onClick={() =>
                                          handleThumbnailClick(e?.image_path)
                                        }
                                      >
                                        <img
                                          preview={false}
                                          height={100}
                                          src={api.rootUrl1 + e?.image_path}
                                          alt={`Thumbnail ${e?._id}`}
                                        />
                                      </SwiperSlide>
                                    )))}
                                  <br />
                                  <br />
                                  <br />
                                </Swiper>

                                <div
                                  className="slider__next"
                                  // onClick={handleNextClick}
                                >
                                  <DownOutlined />
                                </div>
                              </div>

                              <div className="main_image">
                                <Image.PreviewGroup
                                  items={item?.product_images?.map((image) => ({
                                    key: image?._id,
                                    src:
                                      api.rootUrl1 + image?.image_path ||
                                      api.rootUrl1 + products?.images,
                                  }))}
                                >
                                  <Image
                                    // width={400}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "cover",
                                      objectPosition: "center",
                                    }}
                                    src={
                                      api.rootUrl1 +
                                      (foundSpectImg ? foundSpectImg?.[0]?.image_path : (mainImage ||
                                        item?.product_images?.[0]?.image_path))
                                    }
                                    preview={{
                                      images: item?.product_images?.map(
                                        (image) => ({
                                          src:
                                            api.rootUrl1 + image?.image_path ||
                                            api.rootUrl1 + products?.images,
                                        })
                                      ),
                                      visible: true,
                                    }}
                                  />
                                  {/* {item?.product_video?.length > 0 && (
                                    <div
                                      className="video_icon"
                                      onClick={() => setVideoVisible(true)}
                                    >
                                      <Tooltip title="Play Video">
                                        <img src={VideoPlay} alt="Video Play" />
                                      </Tooltip>
                                    </div>
                                  )} */}

                                  {/* <Modal
                                    title={totalProducts?.product?.product_name}
                                    open={isVideoVisible}
                                    onCancel={() => setVideoVisible(false)}
                                    footer={null}
                                    width={1000}
                                    centered
                                  >
                                    <br />

                                    <MediaPlayer
                                      title="Sprite Fight"
                                      src={
                                        api.rootUrl1 +
                                        item?.product_video[0]?.video_path
                                      }
                                    >
                                      <MediaProvider />
                                      <DefaultVideoLayout
                                        thumbnails={VideoPlay}
                                        icons={defaultLayoutIcons}
                                      />
                                    </MediaPlayer>
                                  </Modal> */}
                                </Image.PreviewGroup>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>

                  <div className="individual_product_right">
                    <div className="individual_product_right_wrap">
                      <h1>{specData?.[0]?.product_id?.product_name}</h1>
                      {/* {
                      console.log("totalProducts?.product?.tax_percentage?.tax_percentage", totalProducts?.productspec?.[0]?.discount_id ? totalProducts?.productspec?.[0]?.discount_id?.discount_id?.discount : 0)
                    } */}
                      {
                        // console.log("totalProducts========>", totalProducts?.product?.tax_percentage?.tax_percentage)
                        // console.log("specData", specData)
                        // console.log("specData?????", (dayjs(specData?.[0]?.discount_id?.discount_id?.discount_valid_to).format("DD-MMM-YYYYhh:mm:ss") > dayjs(CurrentDate).format("DD-MMM-YYYYhh:mm:ss")))
                      }
                      <div>
                        <div>
                          <div className="sku">
                            SKU1: {specData?.[0]?.sku || ""}
                          </div>
                          <div className="price">
                            <div className="sp">
                              {dayjs(
                                specData?.[0]?.discount_id?.discount_id
                                  ?.discount_valid_to
                              ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") >
                              dayjs(CurrentDate).format(
                                "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                              )
                                ? specData?.[0]?.sp
                                  ? styles?.currency +
                                    // parseFloat(
                                    //   specData?.[0]?.sp -
                                    //   specData?.[0]?.sp *
                                    //   (specData?.[0]?.discount_id && specData?.[0]?.discount_id
                                    //     ?.discount_id?.discount /
                                    //     100) +
                                    //   ((specData?.[0]?.sp -
                                    //     specData?.[0]?.sp *
                                    //     (specData?.[0]?.discount_id && specData?.[0]?.discount_id
                                    //       ?.discount_id?.discount /
                                    //       100)) *
                                    //     totalProducts?.product?.tax_percentage
                                    //       ?.tax_percentage) /
                                    //   100
                                    // ).toFixed(2)
                                    parseFloat(
                                      specData?.[0]?.discount_id?.discount_id
                                        ?.discount
                                        ? specData?.[0]?.sp +
                                            (specData?.[0]?.sp -
                                              specData?.[0]?.discount_id
                                                ?.discount_id?.discount /
                                                100) *
                                              (totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage /
                                                100)
                                        : specData?.[0]?.sp +
                                            specData?.[0]?.sp *
                                              (totalProducts?.product
                                                ?.tax_percentage
                                                ?.tax_percentage /
                                                100)
                                    ).toFixed(2)
                                  : // `Stock Not Avalilable`
                                    null
                                : specData?.[0]?.sp
                                ? styles?.currency +
                                  parseFloat(
                                    specData?.[0]?.sp +
                                      specData?.[0]?.sp *
                                        (totalProducts?.product?.tax_percentage
                                          ?.tax_percentage /
                                          100)
                                  ).toFixed(2)
                                : // `Stock Not Avalilable`
                                  null}
                            </div>
                            <div className="mrp">
                              {specData?.[0]?.mrp
                                ? styles?.currency +
                                  (
                                    specData?.[0]?.mrp +
                                    (specData?.[0]?.mrp *
                                      totalProducts?.product?.tax_percentage
                                        ?.tax_percentage) /
                                      100
                                  ).toFixed(2)
                                : // `Stock Not Avalilable`
                                  null}
                            </div>
                          </div>
                        </div>
                      </div>
                      {dayjs(
                        specData?.[0]?.discount_id?.discount_id
                          ?.discount_valid_to
                      ).isAfter(
                        dayjs(CurrentDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
                      ) ? (
                        <div style={{ color: "green" }}>
                          {
                            specData?.[0]?.discount_id?.discount_id
                              ?.discount_title
                          }{" "}
                          Discount{" "}
                          {specData?.[0]?.discount_id?.discount_id?.discount}%
                          Applied
                        </div>
                      ) : null}

                      <a
                        className="product_description"
                        href="#descriptionsec"
                        style={{
                          textDecoration: "none",
                          overflowWrap: "anywhere",
                        }}
                        dangerouslySetInnerHTML={{
                          __html:
                            htmlContent?.split(" ").slice(0, 4).join(" ") +
                            "..." +
                            "View more...",
                        }}
                      ></a>
                    </div>
                    <Divider />
                    <div className="product_specification">
                      {totalProducts?.product?.specification?.map(
                        (spec, specIndex) => {
                          const foundSpec = totalProducts?.productspec?.find(
                            (prodSpec) =>
                              prodSpec?.quantity > 0 &&
                              prodSpec?.specification.some(
                                (specDetail) => specDetail?.specification_id === spec?.specification?._id
                              )
                          );
                      
                          const defaultSpecDetailId = foundSpec?.specification.find(
                            (specDetail) => specDetail?.specification_id === spec?.specification?._id
                          )?._id; 

                          // console.log("defaultSpecDetailId-->", defaultSpecDetailId);
                          // console.log("spec?.specification_details[0]?._id", spec?.specification_details[0]?._id);
                          return (
                          <div key={spec?._id} className="col_flex">
                            <strong className="spec_title">
                              {spec?.specification?.name} :
                            </strong>                            
                            <Radio.Group
                              key={spec?._id}
                              className="row_flex"
                              optionType="button"
                              buttonStyle="solid"
                              defaultValue={defaultSpecDetailId || spec?.specification_details[0]?._id}
                              // defaultValue={spec?.specification_details[0]?._id}
                            >
                              {spec?.specification_details?.map(
                                (detail, index) => {
                                  // Normalize the specification details
                                  const normalize = (str) =>
                                    str?.trim().toLowerCase();

                                  // Function to compare normalized specification details
                                  const compareSpecificationDetails = (
                                    specItemDetails,
                                    detailDetails
                                  ) => {
                                    const normalizedSpecItemDetails =
                                      normalize(specItemDetails);
                                    const normalizedDetailDetails =
                                      normalize(detailDetails);
                                    return (
                                      normalizedSpecItemDetails ===
                                      normalizedDetailDetails
                                    );
                                  };

                                  // Check if the current specification detail has a valid combination
            const hasValidCombination = totalProducts?.productspec?.some((prodSpec) =>
              prodSpec?.quantity > 0 &&
              prodSpec?.specification.some((specDetail) =>
                compareSpecificationDetails(
                  specDetail?.specification_details,
                  detail?.specification_details
                )
              )
            );

                                  // Find the matching product specification
                                  // const matchingProductSpec =
                                  //   totalProducts?.productspec.find((spec) =>
                                  //     spec?.specification.some((specDetail) =>
                                  //       compareSpecificationDetails(
                                  //         specDetail?.specification_details,
                                  //         detail?.specification_details
                                  //       )
                                  //     )
                                  //   );

                                  // Quantity for the current detail
                                  // const quantity =
                                  //   matchingProductSpec?.quantity || 0;      
                                  
                                  // Quantity for the current detail
            const quantity = totalProducts?.productspec?.reduce((acc, prodSpec) => {
              if (
                prodSpec?.specification.some((specDetail) =>
                  compareSpecificationDetails(
                    specDetail?.specification_details,
                    detail?.specification_details
                  )
                )
              ) {
                return acc + prodSpec?.quantity;
              }
              return acc;
            }, 0);

                                  // Check if the detail matches with the specification details of the product
                                  // const isMatching =
                                  //   matchingProductSpec?.specification.some(
                                  //     (specDetail) =>
                                  //       compareSpecificationDetails(
                                  //         specDetail?.specification_details,
                                  //         detail?.specification_details
                                  //       )
                                  //   );

                                  return (
                                    <Radio.Button
                                      key={detail?._id}
                                      value={detail?._id}
                                      type="primary"
                                      size="small"
                                      loading={skeletonLoading}
                                      className={
                                        filterSpecs?.length > 0
                                          ? !filterSpecs?.includes(
                                              detail?.specification_details
                                            )
                                            ? "active"
                                            : ""
                                          : index === 0
                                          ? "active"
                                          : ""
                                      }
                                      // disabled={foundSpectQty ? (foundSpectQty <= 0 || !isMatching) : (quantity <= 0 || !isMatching)}
                                      disabled={!hasValidCombination || quantity <= 0}
                                      onClick={() => {
                                        handleSpecClick(
                                          index,
                                          detail?._id,
                                          spec?.specification?._id,
                                          totalProducts?.product?._id
                                        );
                                        setCount(1); 
                                      }}
                                      style={{
                                        position: "relative",
                                        // pointerEvents:
                                        // foundSpectQty ? (foundSpectQty <= 0 ? "none" : "auto") : (quantity <= 0 ? "none" : "auto"),
                                        pointerEvents: quantity <= 0 ? "none" : "auto",
                                      }}
                                    >
                                      <span
                                        // style={{
                                        //   opacity: foundSpectQty ? (foundSpectQty <= 0 ? 0.5 : 1) : (quantity <= 0 ? 0.5 : 1),
                                        // }}

                                        style={{
                                          opacity: quantity <= 0 ? 0.5 : 1,
                                        }}
                                      >
                                        {detail?.specification_details}
                                      </span>
                                      {/* {(foundSpectQty ? (foundSpectQty <= 0 || !isMatching) : (quantity <= 0 || !isMatching)) && ( */}
                                      {quantity <= 0 && (
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            width: "30px",
                                            height: "30px",
                                            transform: "translate(-50%, -50%)",
                                            zIndex: 2,
                                          }}
                                        >
                                          <div
                                            style={{
                                              position: "absolute",
                                              width: "170%",
                                              height: "1px",
                                              backgroundColor: "#ced4da",
                                              top: "50%",
                                              left: "-10px",
                                              transform: "rotate(34deg)",
                                            }}
                                          />
                                          <div
                                            style={{
                                              position: "absolute",
                                              width: "170%",
                                              height: "1px",
                                              backgroundColor: "#ced4da",
                                              top: "50%",
                                              left: "-10px",
                                              transform: "rotate(-35deg)",
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Radio.Button>
                                  );
                                }
                              )}
                            </Radio.Group>
                          </div>
                          )
                        }
                      )}
                    </div>
                    {/* <Divider /> */}
                    <div className="individual_product_right_wrap">
                      <div className="purchase">
                        *Delivery assurance is subject to our delivery locations
                        staying open as per govt. regulations.
                      </div>

                      <div className="shop_btn">
                        {/* {
                          console.log("specdata==>", specData?.[0]?.quantity >= 0)
                        }                         */}
                        <div className="cart_quantity">
                          <Button
                            onClick={() => handleChangeIncrease("minus")}
                            disabled={foundSpectQty ? foundSpectQty <= 0 : specData?.length <= 0}
                          >
                            -
                          </Button>
                          <Input
                            value={count}
                            type="number"
                            readOnly
                            min={1}
                            style={{ textAlign: "center" }}
                          />
                          {/* {console.log("specData?.[0]?.quantity", specData)} */}
                          <Button
                            onClick={() =>
                              handleChangeIncrease(
                                "plus",
                                foundSpectQty ? foundSpectQty : specData?.[0]?.quantity
                              )
                            }
                            disabled={foundSpectQty ? foundSpectQty <= 0 : specData?.length <= 0}
                          >
                            +
                          </Button>
                        </div>
                        <Button
                          htmlType="submit"
                          loading={addToCartLoading}
                          type="primary"
                          onClick={addToCart}
                          disabled={foundSpectQty ? !(foundSpectQty > 0) : !(specData?.[0]?.quantity > 0)}
                        >
                          <ShoppingCartOutlined
                            style={{ display: "inline-flex" }}
                          />
                          Add to Cart
                        </Button>
                        <Button
                          htmlType="submit"
                          loading={wishListLoading}
                          type="primary"
                          onClick={addToWiseList}
                          disabled={foundSpectQty ? foundSpectQty < 0 : specData?.length < 0}
                        >
                          <HeartOutlined style={{ display: "inline-flex" }} />
                          WishList
                        </Button>
                      </div>
                      <div style={{ color: "red" }}>
                        {foundSpectQty ? (foundSpectQty > 0 ? "" : `*Out of Stock`) : (specData?.[0]?.quantity > 0 ? "" : `*Out of Stock`)}
                      </div>
                    </div>
                    {/* <Divider />
                  <div className="product_specification_list">
                    <table>
                      <tbody>
                        <tr>
                          <td>Material</td>
                          <td> : </td>
                          <td>Metal</td>
                        </tr>
                        <tr>
                          <td>Colour</td>
                          <td> : </td>
                          <td>Black</td>
                        </tr>
                        <tr>
                          <td>Capacity</td>
                          <td> : </td>
                          <td>8GB</td>
                        </tr>
                      </tbody>
                    </table>
                  </div> */}
                  </div>
                </div>
              </div>

              <div className="responsive_Slider">
                {specData?.slice(0, 1)?.map((item, index) => {
                  return (
                    <Swiper spaceBetween={10} slidesPerView={3}>
                      {item?.product_images &&
                        item?.product_images?.map((e, i) => (
                          <SwiperSlide key={i}>
                            <img
                              preview={false}
                              width={100}
                              src={api.rootUrl1 + e?.image_path}
                              alt={`Thumbnail ${e?._id}`}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  );
                })}
              </div>

              {specData?.slice(0, 1)?.map((item, index) => (
                <div
                  key={index}
                  className="video_section"
                  style={{
                    // border: "1px solid red",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 0",
                  }}
                >
                  <div>
                    {item?.product_video?.length > 0 && (
                      <div
                        className="video_icon1"
                        onClick={() => setVideoVisible(true)}
                      >
                        <Tooltip title="Play Video">
                          <img src={VideoPlay} alt="Video Play" />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <Modal
                    title={totalProducts?.product?.product_name}
                    open={isVideoVisible}
                    onCancel={() => setVideoVisible(false)}
                    footer={null}
                    width={1000}
                    centered
                  >
                    <br />
                    <MediaPlayer
                      title="Sprite Fight"
                      src={api.rootUrl1 + item?.product_video[0]?.video_path}
                    >
                      <MediaProvider />
                      <DefaultVideoLayout
                        thumbnails={VideoPlay}
                        icons={defaultLayoutIcons}
                      />
                    </MediaPlayer>
                  </Modal>
                </div>
              ))}

              <div className="description_section">
                <Tabs
                  className="description_tab"
                  defaultActiveKey="1"
                  items={items}
                  onChange={onChangeTabs}
                  indicatorSize={(origin) => origin - 16}
                />
              </div>
              <Divider />
              <div className="related_product">
                <h2>Related Product</h2>
                <div className="related_grid">
                  {RelatedProductsFilter?.slice(0, 4).map((item, index) => {
                    // console.log("Related Products:", item);
                    // console.log("Current Item:", item?.specification[index]?.specification_id);
                    return (
                      <div
                        className="product_align_filter_box"
                        key={index}
                        onClick={() => relatedProductClick(item, index)}
                      >
                        <div className="p_image">
                          <img
                            src={
                              api.rootUrl1 + item?.product_images[0]?.image_path
                            }
                            alt="Default"
                          />
                        </div>
                        <div className="p_content">
                          <h2 style={{ color: "black", lineHeight: "25px" }}>
                            {item?.product_id?.product_name}&nbsp; - &nbsp;
                            <Tag color="green">
                              {item?.specification?.map(
                                (spec) => spec?.specification_details
                              )}
                            </Tag>
                          </h2>
                          <div>
                            {/* <Button size="medium" onClick={() => (getSingleProduct1(item?.product_id?._id, index))}>Add to Cart</Button> */}
                            {/* <Tag color="green">{item?.specification?.map((spec) => spec?.specification_details)}</Tag> */}
                          </div>
                          <div className="price">
                            {/* <div className="sp">
                              ₹{" "}
                              {(item?.sp +
                                (item?.sp *
                                  item?.product_id?.tax_percentage
                                    ?.tax_percentage) /
                                  100).toFixed(0)}
                            </div>
                            <div className="mrp">
                              ₹{" "}
                              {item?.mrp +
                                (item?.mrp *
                                  item?.product_id?.tax_percentage
                                    ?.tax_percentage) /
                                  100}
                            </div> */}
                            <Button>Click to view more details</Button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          <Modal
            open={open}
            onCancel={handleCancel}
            onOk={handleOk}
            footer={null}
            title="Login"
            width={400}
          >
            <Form
              layout="vertical"
              form={loginForm}
              name="Login_Form"
              onFinish={login}
            >
              <Form.Item
                label="Enter Mobile number"
                name="buyer_number"
                rules={[
                  {
                    required: true,
                    pattern: /^[0-9]{10}$/,
                    message: "Please enter your Mobile number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Enter Password"
                name="buyer_password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loginLoading}
                  className="primary_btn"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <Or>or</Or>

            <NewAccount>
              <Link to="/register">Click Here</Link>
              to Register a Account
            </NewAccount>
          </Modal>
          <Modal
            open={open1}
            onCancel={handleCancel1}
            onOk={handleOk1}
            footer={null}
            title="Add Address"
            width={300}
          >
            <Button type="primary" onClick={() => navigate("/add-address")}>
              {" "}
              Go to Address Screen{" "}
            </Button>
          </Modal>
        </ProductSection>
      )}
    </>
  );
};

export default Products;

const ProductSection = styled.section`
  width: 100%;
  margin: 60px 0;

  .breadcrumb_section {
    margin: 0 0 15px;
    padding: 0 0 10px;
  }
  .individual_product_section {
    display: flex;
    flex-direction: column;
    gap: 100px;
    width: 100%;
  }

  .individual_product_left_wrap {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }

  .individual_product_align {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .individual_product_left {
    width: 47%;
    display: inline-block;
  }
  .individual_product_right {
    width: 47%;
    display: inline-block;
  }

  .video_icon {
    position: absolute;
    left: 43%;
    bottom: -70px;
  }

  .video_icon img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .video_icon img:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
  }

  .description_section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    border: #cccccc 1px solid;
    padding: 10px;
    border-radius: 10px;

    /* .description_tab{} */
  }

  .remove-button {
    background-color: #ffffff; /* White background, you can change it */
    color: #555555; /* Dark gray text, you can change it */
    border: 1px solid #cccccc; /* Light gray border, you can change it */
    padding: 6px 10px;
    margin-left: 5px; /* Adjust the margin as needed */
    cursor: pointer;
    border-radius: 4px;
    position: relative;
  }

  .slider__prev,
  .slider__next {
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    padding: 0;
    text-align: center;
    box-shadow: 0 0 8px -1px rgba(0, 0, 0, 0.3);
    background-color: hsla(0, 0%, 100%, 0.98);
    cursor: pointer;
    border: 1px solid #e0e0e0;
  }
  .slider__prev:focus,
  .slider__next:focus {
  }

  .cart_quantity {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 9px;
  }
  .cart_quantity button {
    padding: 0;
    height: 28px;
    width: 28px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cart_quantity button span {
    font-size: 16px;
    font-family: var(--semibold);
  }
  .cart_quantity input {
    padding: 5px 8px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    width: 60px;
    text-align: center;
    outline: none;
  }
  /* 
  .active:before,
  .active:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 40px;
    width: 1px;
    background-color: #555555;
    opacity: 0.5;
  } */

  /* .active:before {
    transform: translate(-50%, -50%) rotate(60deg);
  } */
  .swiper {
    flex-direction: column;
    display: flex;
    transition-duration: 0ms;
    transform: translate3d(0px, 0px, 0px);
    border: 1px solid #f5f5f5;
  }

  /* .active:after {
    transform: translate(75%, -50%) rotate(-60deg);
  } */

  /* .active:hover {
    border-color: #464444;
  } */

  .individual_product_left .main_image {
    border: 1px solid #f5f5f5;
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    width: 600px;
    height: 500px;
  }

  .individual_product_left .thump_image {
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* grid-template-columns: repeat(5, 1fr);
    gap: 12px 10px; */
    /* margin: 0 16px; */
  }

  .swiper-wrapper {
    width: 100px !important;
    height: 400px !important;
  }

  .individual_product_left .thump_image .thump_image_box {
    border: 1px solid #eeeaea;
    border-radius: 5px;
    overflow: hidden;
    width: 100px;
    height: 20%;
  }

  .individual_product_left .thump_image .thump_image_box_video {
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    overflow: hidden;
    height: 100px;
    width: 100px;
  }

  .video_icon1 {
    /* position: absolute; */
    /* left: 43%; */
    /* bottom: -70px; */
    /* bottom: -6%; */
    /* left: 54%; */
    /* transform: translate(-50%, -50%); */
    z-index: 100;
  }

  .video_icon1 img {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .video_icon1 img:hover {
    transform: scale(1.5);
    transition: all 0.3s ease-in-out;
  }

  .individual_product_right_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    flex-direction: column;
  }

  .individual_product_right_wrap h1 {
    font-size: 22px;
    font-family: ${font?.semibold};
  }
  .sku {
    font-size: 15px;
    font-family: ${font?.semibold};
  }
  .price {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
  .price div:nth-child(1) {
    font-size: 20px;
    font-family: ${font?.bold};
    color: ${theme?.black};
    line-height: 1.5;
    font-weight: 600;
  }
  .price div:nth-child(2) {
    font-size: 14px;
    font-family: ${font?.light};
    color: ${theme?.gray};
    text-decoration: line-through;
  }
  .product_description {
    font-size: 15px;
    color: ${theme?.gray};
    line-height: 1.5;
    font-family: ${font?.semibold};
    margin-top: 20px;
    margin-bottom: 20px;
    text-overflow: ellipsis;
    max-lines: 4;
  }

  .row_flex {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: 20px;
    /* gap: 9px 9px; */
  }
  .row_flex button span {
    font-size: 13px;
  }
  .purchase {
    font-size: 15px;
    color: ${theme?.gray};
    line-height: 1.5;
  }

  .shop_btn {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }

  .product_specification {
    display: flex;
    flex-direction: column;
  }
  .product_specification .col_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    padding: 5px;
    line-height: 3em;
  }

  .product_specification .row_flex {
    display: flex;
    flex-direction: row;
    line-height: 3em;
    align-items: center;
  }

  .product_specification_list table tbody tr td {
    padding: 5px 0;
  }
  .product_specification_list table tbody tr td:nth-child(1) {
    font-size: 15px;
    color: ${theme?.black};
    font-family: ${font?.medium};
  }
  .product_specification_list table tbody tr td:nth-child(2) {
    padding: 0 10px;
  }
  .product_specification_list table tbody tr td:nth-child(3) {
    font-size: 15px;
    color: ${theme?.gray};
    font-family: ${font?.light};
  }

  .review_section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .Review_Section {
    height: 300px;
    overflow-x: auto;
    width: 75%;
  }
  .Review_Section::-webkit-scrollbar {
    width: 8px;
  }

  .Review_Section::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  .Review_Section::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .review_section .review_left {
    width: 65%;
    display: inline-block;
  }
  .review_section .review_right {
    width: 30%;
    display: inline-block;
  }
  .review_section h2 {
    font-size: 23px;
    margin: 0 0 18px;
  }

  .product_align_filter_box {
    width: 100%;
    border-radius: 7px;
    border: 1px solid #f5f5f5;
    overflow: hidden;
  }
  .p_image {
    width: 100%;
    display: inline-block;
  }
  .p_image img {
    width: 100%;
  }
  .p_content {
    padding: 20px 18px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .related_product .p_content h2 {
    font-size: 17px;
    font-family: ${font?.bold};
    color: ${font?.color};
    margin: 0;
  }
  .price {
    display: flex;
    align-items: center;
    gap: 8px 11px;
  }
  .price div:nth-child(1) {
    color: ${theme?.color};
  }
  .price div:nth-child(2) {
    font-size: 14px;
    color: ${theme?.gray};
    text-decoration: line-through;
  }
  .p_content button {
    padding: 2px 9px;
    height: auto;
    font-size: 14px;
  }
  .p_content button * {
    font-size: 14px;
  }

  .related_product h2 {
    font-size: 23px;
    margin: 0 0 18px;
  }

  .related_grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px 20px;
  }

  .responsive_Slider {
    display: none;
    width: 50%;
    height: 100px;
    margin: 20px auto !important;
    overflow: hidden;
    border: 1px solid red;
  }

  @media screen and (max-width: 1024px) {
    max-width: 1024px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 25px 20px;
    }
    .shop_btn {
      flex-direction: column;
    }
    .main_image {
      height: 325px !important;
    }
  }

  @media screen and (max-width: 768px) {
    max-width: 768px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 323px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
    }
    /* .responsive_Slider {
      display: block;
      border: none;
    } */
    .col_flex .spec_title {
      font-size: small !important;
    }
    .col_flex {
      margin-left: 0px !important;
      flex-wrap: wrap;
      padding: 0px !important;
    }
    .ant-radio-button-wrapper {
      padding: 0px 10px !important;
    }
  }

  @media screen and (max-width: 540px) {
    max-width: 540px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 323px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 280px !important;
    }
    .thump_image_box {
      height: 130px !important;
    }
    .responsive_Slider {
      display: none !important;
      /* border: none; */
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 540px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 63% !important;
      /* margin: 0 auto; */
      /* border: 1px solid red; */
    }
    .individual_product_right {
      width: 63% !important;
      /* border: 1px solid red; */
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    max-width: 425px;
    .related_grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 275px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 230px !important;
    }
    .thump_image_box {
      height: 100px !important;
    }
    .responsive_Slider {
      display: none !important;
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 425px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 47% !important;
    }
    .individual_product_right {
      width: 47% !important;
      margin-top: 20px;
    }
    .individual_product_right_wrap h1 {
      font-size: 20px;
    }
    .individual_product_right_wrap .sku {
      font-size: 14px;
    }
    .individual_product_right_wrap .price .sp {
      font-size: 16px;
    }
    .individual_product_right_wrap .price .mrp {
      font-size: 14px;
    }
    .description_tab {
      font-size: 13px;
    }
    .label h5 {
      font-size: 20px !important;
    }
    .related_product h2 {
      font-size: 20px;
    }
    .p_content h2 {
      font-size: 16px !important;
    }
    .p_content .price .sp {
      font-size: 16px !important;
    }
    .p_content .price .mrp {
      font-size: 14px !important;
    }
  }

  @media screen and (max-width: 375px) {
    max-width: 375px;
    .wrapper {
      padding: 60px 20px !important;
    }
    .related_grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 260px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 210px !important;
    }
    .thump_image_box {
      height: 100px !important;
    }
    .responsive_Slider {
      display: none !important;
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 425px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 46% !important;
    }
    .individual_product_right {
      width: 46% !important;
      margin-top: 20px;
    }
    .individual_product_right_wrap h1 {
      font-size: 18px;
    }
    .individual_product_right_wrap .sku {
      font-size: 12px;
    }
    .individual_product_right_wrap .price .sp {
      font-size: 14px;
    }
    .individual_product_right_wrap .price .mrp {
      font-size: 12px;
    }
    .description_tab {
      font-size: 12px;
    }
    .label h5 {
      font-size: 18px !important;
    }
    .related_product h2 {
      font-size: 18px;
    }
    .p_content h2 {
      font-size: 14px !important;
    }
    .p_content .price .sp {
      font-size: 12px !important;
    }
    .p_content .price .mrp {
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 320px) {
    max-width: 320px;
    .wrapper {
      padding: 60px 20px !important;
    }
    .related_grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 25px 20px;
    }
    .main_image {
      height: 200px !important;
    }
    .shop_btn {
      flex-direction: column;
    }
    .thump_image {
      display: block !important;
      /* border: 1px solid red; */
    }
    .swiper {
      height: 150px !important;
    }
    .thump_image_box {
      height: 100px !important;
    }
    .responsive_Slider {
      display: none !important;
    }
    .individual_product_align {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-width: 425px;
      /* border: 1px solid red; */
    }
    .individual_product_left {
      width: 39% !important;
    }
    .individual_product_right {
      width: 39% !important;
      margin-top: 20px;
    }
    .individual_product_left_wrap {
      gap: 10px !important;
    }
    .individual_product_right_wrap h1 {
      font-size: 16px;
    }
    .individual_product_right_wrap .sku {
      font-size: 10px;
    }
    .individual_product_right_wrap .price .sp {
      font-size: 12px;
    }
    .individual_product_right_wrap .price .mrp {
      font-size: 10px;
    }
    .description_tab {
      font-size: 10px;
    }
    .label h5 {
      font-size: 16px !important;
    }
    .related_product h2 {
      font-size: 16px;
    }
    .p_content h2 {
      font-size: 12px !important;
    }
    .p_content .price .sp {
      font-size: 10px !important;
    }
    .p_content .price .mrp {
      font-size: 10px !important;
    }
    .breadcrumb_section {
      .ant-breadcrumb-link {
        font-size: 10px !important;
      }
    }
  }
`;

const LoginSection = styled.section`
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 580px) {
    padding: 0 20px;
  }
  .primary_btn {
    background: ${styles?.background1};
    /* border: 1px solid ${styles?.colorapi}; */
  }
`;
const LoginAlign = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  flex-wrap: wrap;
  box-shadow: 0 0 40px rgb(0 0 0 / 9%);
  border-radius: 5px;
  margin: 70px 0;
  min-height: 300px;

  @media screen and (max-width: 580px) {
    width: 100%;
    margin: 40px 0;
  }
`;

const LoginRight = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  padding: 35px 35px;
  input {
    width: 100%;
    padding: 8px 14px;
  }
  .Forgot_Password {
    width: 100%;
    text-align: right;
    margin: 0;
    Link {
      color: #000;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  input[type="password"] {
    width: 100%;
    padding: 4px 0px;
  }
  .ant-space {
    width: 100%;
    margin: 0 0 10px;
  }
  button {
    padding: 7px 20px;
    height: auto;
    font-size: 15px;
    background: ${styles.background};
    border: 1px solid ${styles.background};
  }

  .ant-row.ant-form-item-row {
    display: flex;
    flex-direction: column;
  }

  .ant-form label {
    width: 100%;
    display: inline-block;
    text-align: left;
  }

  @media screen and (max-width: 580px) {
    padding: 30px 25px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  color: #000;
  font-weight: 700;
  width: 100%;
  margin: 0 0 20px;
  text-transform: uppercase;
`;

const Or = styled.div`
  width: 100%;
  font-size: 15px;
  text-align: center;
  font-style: italic;
`;
const LoginOtp = styled.div`
  font-size: 14px;
  line-height: 1.5;
  margin: 10px 0 0 0;
  width: 100%;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
const ForgetPassword = styled.div`
  margin: 25px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
`;
const NewAccount = styled.div`
  margin: 8px 0 0 0;
  text-align: center;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;
